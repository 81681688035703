import { Theme } from '@mui/material/styles';
import * as Schema from 'generated/graphql/schema';
import type { AxisPlotBandsOptions } from 'highcharts';
import { findLastIndex, sortBy, uniqBy } from 'lodash';

import { CHART_COLORS, MANUAL_PROCESS_COLORS, MEASUREMENT_CHART_COLORS } from '@/lib/highcharts/data-config';
import { movingAverage } from '@/lib/highcharts/filters';
import * as Types from '@/types';

export type Point<VALUE = number | null> = {
  data: {
    accValue: VALUE;
    minValue: VALUE;
    maxValue: VALUE;
    count: number;
  };
  timeRange: {
    from: string;
    to: string;
  };
};

export type Sample = Point<number>;
export const isSample = (sample: Point): sample is Sample => typeof sample.data.accValue === 'number';

export type VoidData = Point<null>;
export const isVoidData = (sample: Point): sample is VoidData => sample.data.accValue === null;
const isVoidPoint = <T extends { low?: number } | undefined>(point?: T) => !!point && typeof point.low !== 'number';

export type HighchartsPoint = { x: number; y: number | null };
export type HighchartsVariancePoint = { x: number; low?: number; high?: number };
export type HighchartsIntermediatePoint = HighchartsVariancePoint & {
  y: number;
  count: number;
  globalLow: number;
  globalHigh: number;
};

const diff = (a: string | number | Date, b: string | number | Date) => {
  return new Date(a).getTime() - new Date(b).getTime();
};

type WindowedSnapshot<I, O> = {
  prev: I | undefined;
  current: O;
  next: I | undefined;
};

export const dataFilters = [movingAverage(1)];

const maybeApplyDiscontinuity = <O extends { x: number }, I extends O = O>(
  { prev, current, next }: WindowedSnapshot<I, O>,
  predicate: (point?: I) => boolean,
) => {
  const slice: O[] = [];

  if (predicate(prev)) {
    slice.push({ x: current.x - 1 } as O);
  }

  slice.push(current);

  if (predicate(next)) {
    slice.push({ x: current.x + 1 } as O);
  }

  return slice;
};

type PointProcessor<C extends {} = {}> = (
  config: C,
) => (
  point: HighchartsIntermediatePoint,
  index: number,
  points: HighchartsIntermediatePoint[],
) => HighchartsVariancePoint[] | HighchartsPoint[];

const instantaneousSpeedProcessor: PointProcessor<{ scale: number; perPointSpan: number }> =
  ({ scale, perPointSpan }) =>
  (point, index, points) => {
    if (isVoidPoint(point)) {
      return [];
    }

    const [firstPoint, prev, next] = [points[0], points[index - 1], points[index + 1]];

    if (!prev) {
      return [];
    }

    const isPreviousPointVoid = isVoidPoint(prev);

    const pastPoint = isPreviousPointVoid
      ? points[findLastIndex(points, (point) => !isVoidPoint(point), index - 1)] || firstPoint
      : prev;

    // NOTE: If a device has been offline for a while, perPointSpan may not be the right duration to pick, we effectively
    // need the last online sample.
    const duration = isPreviousPointVoid ? point.x - pastPoint.x : perPointSpan;

    return maybeApplyDiscontinuity<HighchartsPoint, HighchartsIntermediatePoint>(
      {
        prev,
        current: evaluateSpeed(
          {
            value: point.y - pastPoint.y,
            timestamp: point.x,
          },
          duration,
          scale,
        ),
        next,
      },
      isVoidPoint,
    );
  };

export const getSerieColor = (type: Schema.SensorType, sensorIndex: number): { color: string; variance: string } => {
  if (type === Schema.SensorType.MEASUREMENT) {
    return MEASUREMENT_CHART_COLORS[sensorIndex % MEASUREMENT_CHART_COLORS.length];
  } else if (type === Schema.SensorType.MANUAL_PROCESS) {
    return MANUAL_PROCESS_COLORS[sensorIndex % MANUAL_PROCESS_COLORS.length];
  } else {
    return CHART_COLORS[sensorIndex % CHART_COLORS.length];
  }
};

const averageSpeedProcessor: PointProcessor<{ scale: number; perPointSpan: number }> =
  ({ scale, perPointSpan }) =>
  (point, index, points) => {
    if (!points.length) {
      return [];
    }

    if (isVoidPoint(point)) {
      return [];
    }

    const lookBackNumIndices = Math.ceil(scale / perPointSpan) || 1;
    const pastPoint = (() => {
      const pastIndex = Math.max(0, index - lookBackNumIndices);
      const pastPoint = points[pastIndex];

      return (
        (isVoidPoint(pastPoint) && points[findLastIndex(points, (point) => !isVoidPoint(point), pastIndex)]) ||
        pastPoint
      );
    })();

    if (pastPoint === point) {
      return [];
    }

    const duration = point.x - pastPoint.x;
    const [prev, next] = [points[index - 1], points[index + 1]];

    return maybeApplyDiscontinuity<HighchartsPoint, HighchartsIntermediatePoint>(
      {
        prev,
        current: evaluateSpeed(
          {
            value: point.y - pastPoint.y,
            timestamp: point.x,
          },
          duration,
          scale,
        ),
        next,
      },
      isVoidPoint,
    );
  };

const normalizeProcessor: PointProcessor<{ average: boolean }> =
  ({ average }) =>
  (point, index, points) => {
    if (isVoidPoint(point)) {
      return [];
    }

    const [prev, next] = [points[index - 1], points[index + 1]];

    if (!prev) {
      return [];
    }

    return maybeApplyDiscontinuity<HighchartsPoint, HighchartsIntermediatePoint>(
      {
        prev,
        current: normalizePoint({
          value: point.y - prev.y,
          timestamp: point.x,
          count: average ? point.count : 1,
        }),
        next,
      },
      isVoidPoint,
    );
  };

const uptimeProcessor: PointProcessor = () => (point, index, points) => {
  if (isVoidPoint(point)) {
    return [];
  }

  const prev = points[index - 1];

  if (!prev) {
    return [];
  }

  return [
    {
      x: point.x,
      y: point.y - prev.y ? 1 : 0,
    },
  ];
};

const voidProcessor: PointProcessor<{ average: boolean; now: number }> =
  ({ average, now }) =>
  (current, index, points) => {
    const isBeforeMostRecentMinute = () => current.x < now - (1).minutes;

    if (!isVoidPoint(current) || !isBeforeMostRecentMinute() || index === points.length - 1) {
      return [];
    }

    const [prev, next, last] = [points[index - 1], points[index + 1], points[points.length - 1]];

    const y = average ? (last.globalHigh + last.globalLow) / 2 : 0;

    return maybeApplyDiscontinuity<HighchartsPoint, HighchartsIntermediatePoint>(
      { prev, current: { ...current, y }, next },
      (point) => !isVoidPoint(point),
    );
  };

const varianceProcessor: PointProcessor<{ isMeasurement: boolean }> =
  ({ isMeasurement }) =>
  (current, index, points) => {
    if (!isMeasurement) {
      return [];
    }

    if (isVoidPoint(current)) {
      return [];
    }

    const [prev, next] = [points[index - 1], points[index + 1]];

    return maybeApplyDiscontinuity<HighchartsVariancePoint, HighchartsIntermediatePoint>(
      { prev, current, next },
      isVoidPoint,
    );
  };

export const pointProcessors = {
  NONE: normalizeProcessor,
  UPTIME: uptimeProcessor,
  SPEED: instantaneousSpeedProcessor,
  AVERAGE_SPEED: averageSpeedProcessor,
  VOID: voidProcessor,
  VARIANCE: varianceProcessor,
};

type ComposerFunction = (
  point: HighchartsIntermediatePoint,
  index: number,
  points: HighchartsIntermediatePoint[],
) => HighchartsPoint[] | HighchartsVariancePoint[];
export const pipePoints = (samples: Point[], composers: Array<ComposerFunction>) => {
  // NOTE: Pipe composers with pass-by-reference collection (the processor
  // mutates)
  const pipe = composers.reduce(
    (pipe, composer, composerIndex) => {
      return (
        collections: Array<HighchartsPoint[] & HighchartsVariancePoint[]>,
        point: HighchartsIntermediatePoint,
        index: number,
        points: HighchartsIntermediatePoint[],
      ) => {
        collections[composerIndex].push(...composer(point, index, points));

        return pipe(collections, point, index, points);
      };
    },
    (
      collections: Array<HighchartsPoint[] & HighchartsVariancePoint[]>,
      _point: HighchartsIntermediatePoint,
      _index: number,
      _points: HighchartsIntermediatePoint[],
    ) => collections,
  );

  const [normalizedCumSum] = samples.reduce<[HighchartsIntermediatePoint[], number, number, number]>(
    ([cumsum, prevMinValue, prevValue, prevMaxValue], sample) => {
      const { minValue, accValue, maxValue, count } = sample.data;
      const value = prevValue + (accValue || 0);
      const globalLow = Math.min(prevMinValue, minValue || 0);
      const globalHigh = Math.max(prevMaxValue, maxValue || 0);

      cumsum.push({
        x: new Date(sample.timeRange.to).getTime(),
        y: value,
        low: typeof minValue === 'number' ? minValue : undefined,
        high: typeof maxValue === 'number' ? maxValue : undefined,
        count,
        globalLow,
        globalHigh,
      });

      return [cumsum, globalLow, value, globalHigh];
    },
    [[], Infinity, 0, -Infinity],
  );

  return normalizedCumSum.reduce(pipe, [...composers.map(() => [])]);
};

export const getMissingData = (p: Schema.Sample) => {
  return {
    // FIXME: Type this properly
    x: new Date(p.timeRange.to!).getTime() || undefined,
    y: p.data.accValue === null ? 0 : undefined,
  };
};

export const normalizePoint = ({ timestamp, value, count }: { timestamp: number; value: number; count: number }) => {
  return {
    x: timestamp,
    y: value / (count || 1),
  };
};

export const evaluateSpeed = (
  { timestamp, value }: { timestamp: number; value: number },
  duration: number,
  scale: number,
) => {
  return {
    x: timestamp,
    y: (value / (duration || 1)) * scale,
  };
};

export const getRangeData = (p: Point, type: string, chartTimeScale = Schema.ChartTimeScale.MINUTE) => {
  const timeDiff = diff(p.timeRange.to, p.timeRange.from) === 0 ? 1 : diff(p.timeRange.to, p.timeRange.from);

  if (type === Schema.SensorType.MEASUREMENT) {
    return {
      x: new Date(p.timeRange.from).getTime(),
      low: p.data.minValue !== undefined && p.data.minValue !== null ? p.data.minValue : undefined,
      high: p.data.maxValue !== undefined && p.data.maxValue !== null ? p.data.maxValue : undefined,
    };
  }

  const count = p.data.count || 1;

  return {
    x: new Date(p.timeRange.from).getTime(),
    low:
      p.data.minValue !== undefined && p.data.minValue !== null
        ? ((p.data.minValue * count) / timeDiff) *
          (chartTimeScale === Schema.ChartTimeScale.HOUR ? (1).hours : (1).minutes)
        : undefined,
    high:
      p.data.maxValue !== undefined && p.data.maxValue !== null
        ? ((p.data.maxValue * count) / timeDiff) *
          (chartTimeScale === Schema.ChartTimeScale.HOUR ? (1).hours : (1).minutes)
        : undefined,
  };
};

export const getBatchAndStopsPlotBands = (
  batches: Array<Pick<Schema.Batch, 'batchId' | 'actualStart' | 'actualStop'>>,
  stops: Schema.Stop[] = [],
  time: Types.TimeDate | null = null,
  theme: Theme,
  showStopLabels?: Boolean | null,
): AxisPlotBandsOptions[] => {
  batches = uniqBy(batches, 'batchId');
  batches = sortBy(batches, ['actualStart']);
  batches = !time
    ? batches
    : batches.filter((b) => {
        if (time.to && b.actualStart && new Date(b.actualStart) >= time.to) {
          return false;
        } else if (time.from && b.actualStop && new Date(b.actualStop) <= time.from) {
          return false;
        }
        return true;
      });

  const batchColors = ['rgba(100,100,100,0.2)', 'rgba(50,130,210,0.2)'];

  const batchesPlotBands = batches
    .map((batch, i): AxisPlotBandsOptions | undefined => {
      if (!batch.actualStart) {
        return;
      }

      return {
        from: new Date(batch.actualStart).getTime(),
        to: batch.actualStop ? new Date(batch.actualStop).getTime() : Date.now(),
        color: batchColors[i % batchColors.length],
        id: batch.batchId,
        zIndex: 5,
        label: {
          // @ts-ignore - no longer available?
          useHTML: true,
          text: '',
          style: {
            width: 100,
            textOverflow: 'ellipsis',
            title: '',
          },
          rotation: 90,
          textAlign: 'left',
          verticalAlign: 'top',
        },
      };
    })
    .filter(Types.isTruthy);

  const stopPlotBands = (stops || []).map((stop): AxisPlotBandsOptions => {
    const causeType = stop.stopCause
      ? stop.stopCause.stopType
      : ('UNREGISTERED_PLOT_BAND' as keyof typeof theme.blackbird.stops);
    return {
      from: new Date(stop.timeRange.from!).getTime(),
      to: new Date(stop.timeRange.to!).getTime(),
      color: theme.blackbird.stops[causeType].color.main,
      id: stop.timeRange.from!,
      label: {
        // @ts-ignore - no longer available? It's SVGElement now, so perhaps we
        // can render it directly with React instead.
        useHTML: true,
        text: showStopLabels && stop.stopCause ? stop.stopCause.name : '',
        style: {
          width: 100,
          textOverflow: 'ellipsis',
          title: stop.stopCause ? stop.stopCause.name : '',
        },
        rotation: 90,
        textAlign: 'left',
        verticalAlign: 'top',
      },
      zIndex: 0,
    };
  });

  return batchesPlotBands.concat(stopPlotBands);
};

// FIXME: it will be nice to remove the code duplication from the different plot bands and
// use some function to get constants like the zIndex or the color
export const getOverridesPlotBands = (
  overrides: Array<Pick<Schema.DataOverride, 'timeRange' | 'author' | 'comment'>>,
  time: Types.TimeDate | null = null,
  theme: Theme,
  onClickHandler?: <T>(input: T) => void,
): AxisPlotBandsOptions[] => {
  return (overrides || []).map((override) => {
    return {
      from: new Date(override.timeRange.from!).getTime(),
      to: new Date(override.timeRange.to!).getTime(),
      color: 'rgba(210, 50, 90, 0.24)',
      id: override.timeRange.from!,
      label: {
        // @ts-ignore
        useHTML: true,
        // FIXME: if the text is too long maybe show a part of it and the rest if the mouse goes over ...
        text: `${override.author}: ${override.comment}`,
        rotation: 90,
        textAlign: 'left',
        verticalAlign: 'top',
      },
      zIndex: 7,
      events: {
        click(e) {
          if (!e) {
            return;
          }

          if (onClickHandler) {
            e.preventDefault();
            onClickHandler(override);
          }
        },
      },
    };
  });
};
